import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Form from "./components/Form";
import Results from "./components/Results";

class App extends Component {
  state = {
    data: null,
    form: "",
    climateForm: "",
    vaccineForm: ""
  };

  handleFormSubmit = (event, form) => {
    event.preventDefault();
    localStorage.setItem("form", JSON.stringify(form));
    console.log(form); 
    console.log('click submit')
    // window.open(`http://localhost:3000/?oid=16&affid=47&${window.location.search.replace('?', '')}`, "_blank");
    window.open(`https://widget.calivols.volunteermatch.org/?oid=16&affid=47&${window.location.search.replace('?', '')}`, "_blank");
    this.setState({
      form: form
    });
  }

  handleFormSubmitResults = (event, form) => {
    event.preventDefault();
    this.setState({
      form: form
    });
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <div className="App">
        <Routes>
          <Route exact path="/" element={params.header === "false" ?
              <>
                <div className={"body-container"}>
                  <Form handleFormSubmit={this.handleFormSubmitResults} />
                  <Results form={this.state.form} />
                </div>
              </>
              :
              <>
                <Header />
                <div className={"body-container"}>
                  <Form handleFormSubmit={this.handleFormSubmitResults} />
                  <Results form={this.state.form} />
                </div>
              </>
          } />
          <Route exact path="/searchwidget" element={<Form handleFormSubmit={this.handleFormSubmit} />} />
          <Route exact path="/searchwidgetclimate" element={<Form handleFormSubmit={this.handleFormSubmit} widget={"climate"} />} />
          <Route exact path="/searchwidgetvaccine" element={<Form handleFormSubmit={this.handleFormSubmit} widget={"vaccine"} />} />
          <Route exact path="/searchwidgetamericorps" element={<Form handleFormSubmit={this.handleFormSubmit} widget={"americorps"} />} />
        </Routes>
      </div>
    );
  }
}

export default App;
