import React from "react";
import { Link } from "react-router-dom";
import "../Results/Results.css";
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import axios from "axios";
import parse from 'html-react-parser';


class Results extends React.Component {
  state = {
    defaultLocation: "California, CA",
    showModal: false,
    connectionModal: false,
    firstName: "",
    lastName: "",
    zip: "",
    email: "",
    country: "USA",
    comment: "",
    phone: "",
    terms: false,
    termsTwo: false,
    form: "",
    formSubmitted: false,
    opp: {},
    vmData: "",
    widget: localStorage.getItem("widget") || ""
  }

  componentDidMount(){
    if(!this.props.forms && localStorage.getItem("form")) {
      this.setState({
        form: JSON.parse(localStorage.getItem("form"))
      })
    }
    else if(this.props.forms) {
      this.setState({
        form: this.props.form
      })
    }
    else {
      axios.post("https://widget-backend.eng.impactonline.org/getVmDataCAforAll", {
        form: {
          location: 'California, USA',
          virtual: false,
        },
        arr: [],
        greatFor: [],
        keywordsArr: '[""]',
        widget: localStorage.getItem("widget") || ""
      }).then(data=>{
        this.setState({
          vmData: data.data,
          form: "",
          widget: "",
        }, ()=>localStorage.removeItem("widget"))
      }).catch(err=>{
        console.log(err)
      })
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.form !== this.props.form){
      this.setState({
        form: this.props.form
      })
    }
    if(prevState.form !== this.state.form && this.state.form){
      this.setState({
        vmData: ""
      })
      const object = this.state.form
      let arr = [];
      let greatFor = []
      let keywordsArr = JSON.stringify(object.keywords.replace(/[^a-zA-Z0-9 ]/g, "").split(" "))
          if (object["category"]) {
          if(object["category"] === "food"){
            arr.push("hunger")
          }
          else if(object["category"] === "climate") {
            arr.push("environment")
          }
          else if(object["category"] === "education"){
            arr.push("educationAndLiteracy", "childrenAndYouth")
          }
          else if(object["category"] === "disasters"){
            arr.push("disasterRelief")
          } 
        }

        if(object["goodFor"]){
          greatFor.push(object["goodFor"])
        }

        axios.post("https://widget-backend.eng.impactonline.org/getVmDataCAforAll", {
          form: this.state.form,
          arr,
          greatFor,
          keywordsArr,
          widget: localStorage.getItem("widget") || ""
        }).then(data=>{
          this.setState({
            widget: "",
            vmData: data.data
          }, ()=>localStorage.removeItem("widget"))
        })
    }
    if(prevState.vmData !== this.state.vmData && this.state.vmData !== ""){
      localStorage.removeItem("form")
    }
  }

  handleConnectionInput = (e) => {
    if(e.target.name === "terms"){
      this.setState({
        terms : e.target.checked
      })
    }
    else if(e.target.name === "termsTwo"){
      this.setState({
        termsTwo : e.target.checked
      })
    }
    else {
      this.setState({
        [e.target.name] : e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios.post(
      "https://widget-backend.eng.impactonline.org/createConnectionCAforAll",
      {
        opp: this.state.opp,
        comments: this.state.comment,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phone,
        zipCode: this.state.zip,
      }
    ).then((response) => {
      this.setState({
        formSubmitted: true,
        firstName: "",
        lastName: "",
        zip: "",
        country: "USA",
        comment: "",
        phone: "",
        terms: false,
        termsTwo: false
      })
    }).catch((error) => {
      console.log(error);
    });
  }
  
  render() {
    const regex = /(<([^>]+)>)/ig;
    const regexEnt = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig;
    let scrollY = document.body.style.top;

    return (
      <>
          <div className={"results-container"} style={false ? {position: "fixed", overflow: "hidden", top: `-${window.scrollY}px`} : {position: "", top: (parseInt(scrollY || '0') * -1)}}>
            <div className={"city-header"}>
              {
                this.state.form?.virtual
                ? <h2>Virtual Opportunities</h2>
                : <h2>Opportunities near {startCase(this.state.form?.location || this.state.defaultLocation)} </h2>
              }
            </div>
            <div className={"results-inner"}>
              {/* {console.log("opps 317", data.searchOpportunities.opportunities)} */}
             {
               (this.state.vmData === "")
                ? <div className={"no-results"}><h2>Retrieving results...</h2></div>
                : (this.state.vmData.opportunities?.length > 0) 
                  ? 
                  this.state.vmData.opportunities.filter(category => (category.categories.indexOf("advocacyAndHumanRights") === -1 && category.categories.indexOf("politics") === -1))
                  .map((opportunities, index) => {
                    // console.log(opportunities);
                    return (
                      <div className={"cards-container"} key={index}>
                        <div className={"cards"} key={index}>
                        {/* { console.log("opps ", opportunities) } */}
                          <div className={"card-left"}>
                            <div className={"top-card"}>
                              <div className={"opp-img"}>
                                <div className={"img-inner"}>
                                <img src={opportunities.imageUrl === null ? "./img/CV-Volunteer-Icon.png": opportunities.imageUrl} className="card-img-top" alt="oppotunity-img" />
                                </div>                
                              </div>
                              <div className={"org-date-cont"}>
                                <h3>{opportunities.parentOrg.name}</h3>
                                <span>Location: { opportunities.location.virtual === true ? "Virtual Opportunity" : `${opportunities.location.city}, ${opportunities.location.region}`}</span>
                                <span>Date: {opportunities.dateRange.startDate === null ? "Open Ended" : `${opportunities.dateRange.startDate} to ${opportunities.dateRange.endDate}`}</span>
                              </div> 
                            </div>
                            <div className={"card-body"}>
                              <div className={"opp-title-cont"}>
                                <div className={"opp-title"}>
                                  <h3>{opportunities.title}</h3>
                                </div>
                                <div className={"opp-desc"}>
                                  {/* {console.log("desc " + opportunities.description)} */}
                                  <p>{opportunities.description.replace(regex,'').replace(regexEnt, (e)=>{
                                      switch(e) {
                                        case '&nbsp;': 
                                            return ' ';
                                        case '&tab;': 
                                            return '\t';
                                        case '&copy;': 
                                            return String.fromCharCode(169);
                                        case '&quot;':
                                        case '&rsquo;':
                                          return "'";
                                        case '&amp;':
                                          return  "&";                            
                                        default: 
                                            return e;
                                    }
                                  })}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={"card-right"}>

                            <div className={"learn-more"} onClick={()=>{this.setState({showModal: true, opp: opportunities, formSubmitted: false})}}> 
                              <span>Learn More</span>
                            </div>

                            <div className={"category-cont"}>
                              <h4>Categories:</h4>
                                { opportunities.categories.map((category, index) => {
                                  return <div key={index} className={"category-span-cont"}>
                                      <p className={"category-spans"}>{startCase(camelCase(category))}</p>
                                  </div>
                                })
                               
                                }
                            </div>

                            <div className="great-for-cont">
                  

                              {
                                opportunities.greatFor?.length > 0 
                                  ? <>
                                      <h4>Great For:</h4>
                                      {opportunities.greatFor?.map((item, index)=>{
                                        return <div key={index} className={"gf-cont"}>
                                            <p className={"gf-spans"}>{startCase(camelCase(item))}</p>
                                        </div>
                                      })}
                                    </>
                                  : <div></div>
                              }
                            </div>

                          </div>
                      </div>
                    </div>
                    )
                  }) 
                : <div className={"no-results"}><h2>Your search returned no results, please narrow your search and try again!</h2></div> } 
          </div>
        </div>
        <div className = {this.state.showModal ? 'showModal' : 'hideModal'}>
          <div className={"inner-modal"}>
            <div className={"closeModal"} onClick={()=> this.setState({showModal: false})} >
              {/* <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g className="fa-group"><path className="fa-secondary" fill="#C7004C" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z" opacity="0.4"></path><path className="fa-primary" fill="#fff" d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path></g></svg> */}
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
            </div>
            <div className={"modal-inner-top"}>
              <div className={"modal-top-left"}>
                <div className={"modal-opp-title"}>
                    {/* <div className={"img-inner-modal"}>
                    <img src={this.state.opp.imageUrl === null ? "https://bespokedemo.com/wp-content/uploads/2020/01/VolunteerMatch-Logo.png": this.state.opp.imageUrl} className="card-img-top-modal" alt="oppotunity-img" />
                    </div>  */}
                  <h2>{this.state.opp?.title}</h2>
                </div>
                <div className="modal-opp-location">
                  <h3>{this.state.opp.parentOrg?.name}</h3>
                </div>
              </div>
              <div className={"modal-top-right"}>
                {/* <Link to={{pathname: this.state.opp.url}} target="_blank"> */}
                <span onClick={()=>this.setState({connectionModal: true})}>CONNECT</span>
              </div>
            </div>
            <div className={"modal-inner-btm"}>
              <div className={"modal-btm-left"}>
                <div className={"about-container"}>
                 <h4>ABOUT</h4>
                  <p>{this.state.opp.description? parse(this.state.opp.description) : " " }</p>
                </div>
              </div>
              <div className={"modal-btm-right"}>
                <div>
                 <h4>Dates</h4>
                 <div className="modal-side-inner">
                  <p className={"modal-spans"}>
                    {
                      this.state.opp.dateRange?.ongoing === true 
                        ? "Ongoing" 
                        : <>
                          {this.state.opp.dateRange?.startDate}
                            <span style={{margin: 0}}>to</span>
                          {this.state.opp.dateRange?.endDate}
                          </>
                    }
                  </p>
                 </div>
                </div>

                <div className={ this.state.opp.requirements?.bgCheck === false && this.state.opp.requirements?.drLicense === false && this.state.opp.requirements?.minimumAge === null && this.state.opp.requirements?.orientation === false ? "requirements-cont-hide" : "requirements-cont" }>
                 <h4>Requirements</h4>
                 <div className={"modal-side-inner"}>
                  <span className = {this.state.opp.requirements?.bgCheck ? 'modal-spans' : 'hide-span'}>{this.state.opp.requirements?.bgCheck === true ? "Background Check" : null }</span>
                  <span className = {this.state.opp.requirements?.drLicense ? 'modal-spans' : 'hide-span'}>{this.state.opp.requirements?.drLicense === true ? "Drivers License" : null }</span>
                  <span className = {this.state.opp.requirements?.minimumAge ? 'modal-spans' : 'hide-span'}>{this.state.opp.requirements?.minimumAge ? "Minimum Age: " + this.state.opp.requirements?.minimumAge : null }</span>
                  <span className = {this.state.opp.requirements?.orientation ? 'modal-spans' : 'hide-span'}>{this.state.opp.requirements?.orientation === true ? "Orientation" : null }</span>
                  </div>
                </div>

                <div className={this.state.opp.skillsNeeded? "" : "requirements-cont-hide"}>
                 <h4>Skills Required</h4>
                 <div className={"modal-side-inner"}>
                  {
                    this.state.opp.skillsNeeded?.split(";").filter(f =>{ return f.length >  1}).map((i, index) => { return <span key={index} className={"skills-span"}>{i}</span> })}
                 </div>
                </div>


                <div className={this.state.opp.greatFor === null ? "gf-hide" : "gf-cont"}>
                  <h4>Great For</h4>
                  {
                 this.state.opp.greatFor?.length > 0 
                  ? <div className={"modal-span-cont"}>
                      {this.state.opp.greatFor?.map((item, index)=>{
                        return <p className={"modal-spans"} key={index}>{item}</p>
                      })}
                    </div>
                  : <div className={"modal-span-cont"}></div>
                  }
                </div>

                <div className={this.state.opp.categories?.length > 0 ? "gf-cont" : "gf-hide"}>
                  <h4>Categories:</h4>
                                      {
                  this.state.opp.categories?.length > 0 
                    ? <div className={"modal-span-cont"}>
                        {this.state.opp.categories?.map((item, index)=>{
                          return <p className={"modal-spans"} key={index}>{startCase(camelCase(item))}</p>
                        })}
                      </div>
                    : <div className={"modal-span-cont"}></div>
                  }
                </div>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", paddingTop: "30px", flexFlow: "column", borderTop: "5px solid #ff8400" }}>
                <span style={{fontSize: "16px", color: "#214080", margin: 0}}>
                  Powered by:
                </span>
                <img src="https://solutions.volunteermatch.org/hs-fs/hubfs/VM%20logo%20color%20-%20black%20text%20no%20tagline%20-%20transparent.png?width=632&height=139&name=VM%20logo%20color%20-%20black%20text%20no%20tagline%20-%20transparent.png" alt="Volunteer-Match-Logo" style={{position: "relative", maxWidth: "100%", width: "250px", margin: "20px"}}></img>
              </div>  
          </div>

        </div>


        <div className = {this.state.connectionModal ? 'showConnectionModal' : 'hideModal'}>
          <div className={"make-connection-inner"}>
            <div className={"closeModal"} onClick={()=> this.setState({connectionModal: false})} >
              {/* <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g className="fa-group"><path className="fa-secondary" fill="#C7004C" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z" opacity="0.4"></path><path className="fa-primary" fill="#fff" d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path></g></svg> */}
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#333" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>

            </div>
            <div className={"mc-img-cont"}>
               <img src={"https://www.californiavolunteers.ca.gov/wp-content/uploads/sites/116/2020/04/Californians-For-All.png"} alt={"cal-vols-logo"}></img>
            </div>
          {
            this.state.formSubmitted 
              ? <div className={"thank-you-msg"}><p>Thank you for joining Californians For All! We've let the organization know you are ready to volunteer and they'll be contacting you soon. We have sent you an email with the organization's contact information.</p></div>
              :
                <form onSubmit={this.handleSubmit} className={"mc-form"}>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.firstName} name="firstName" placeholder="First Name" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.lastName} name="lastName" placeholder="Last Name"  onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.email} placeholder="Email" name="email" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.zip} name="zip" placeholder="Zip Code" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.country} name="country" placeholder="Country" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-cont"}>
                    <input type="text" value={this.state.phone} name="phone" placeholder="Phone" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"connection-field-msg-cont"}>
                    Send a message to {this.state.opp.parentOrg?.name} with questions or comments about this volunteer opportunity.
                    <br />
                    <textarea value={this.state.comment} name="comment" placeholder="Send a message (Optional)" onChange={this.handleConnectionInput} />
                  </div>
                  <div className={"tnc-cont"}>
                    <input type="checkbox" checked={this.state.terms} name="terms" onChange={this.handleConnectionInput} />
                    I agree to VolunteerMatch <Link to={{ pathname:"https://www.volunteermatch.org/legal/terms.jsp"}} target="_blank">Terms of Use</Link>
                  </div>
                  <div className={"tnc-cont"}>
                    <input type="checkbox" checked={this.state.termsTwo} name="termsTwo" onChange={this.handleConnectionInput} />
                    I agree to the State of California <Link to={{ pathname:"https://www.ca.gov/use/"}} target="_blank">Terms of Use</Link>
                  </div>
                  <button disabled={!this.state.terms || !this.state.termsTwo || !this.state.firstName || !this.state.lastName || !this.state.zip || !this.state.country} type="submit" className={"mc-sub-btn"}>SUBMIT</button>
                </form>
          }
          
          </div>
        </div>
      </>
    );
  }
}

export default Results;