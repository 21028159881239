import React from "react";
import "../Header/Header.css";

class Header extends React.Component {
  
  render() {
    return (
      <div className={"header"}>
        <div className={"header-left"}>
          <a href="http://californiavolunteers.ca.gov">
            <img src={"./img/white-caforall-logo.png"} className={"header-img-left"} alt="ca-for-all-img" />
          </a>
        </div>
        <div className={"header-middle"}>
          <div className={"title"}>
            Join #CaliforniansForAll
          </div>
          <div className={"subtitle"}>
            Answer the Governor's call to service by helping your community
          </div>
          <div className={"action"}>
            Search for volunteer opportunities in your area
          </div>
        </div>
        <div className={"header-left header-right"}>
          <a href="http://californiavolunteers.ca.gov">
            <img src={"./img/California-Volunteers-for-color-bkgrds.png"} className={"header-img-right"} alt="ca-vol-img" />
          </a>
        </div>
      </div>
    );
  }
}

export default Header;