import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

// const httpLink = new HttpLink({ uri: 'https://graphql-calivols.dev.impactonline.org/' });
const httpLink = new HttpLink({ uri: 'https://graphql.volunteermatch.org/graphql' });
const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'x-api-key': 'f8535e3a23213b6f20f9ca69e2d1cf01',
        // 'x-api-key': 'f423bb1de506857ee07d6c66510f88d4',
      }
    });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
  <ApolloProvider client={client}>                 
      <App/>
  </ApolloProvider>
  </BrowserRouter>, 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
