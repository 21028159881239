import React from "react";
import "../Form/Form.css";

class Form extends React.Component {
  state = {
    location: "California, USA",
    virtual: false,
    // food: false,
    // education: false,
    // healthAndSafety: false,
    // services: false,
    category: this.props.widget === "climate" ? "climate" : this.props.widget === "vaccine" ? "vaccine" : this.props.widget === "americorps" ? "americorps" : "",
    // kids: false,
    // seniors: false,
    // teens: false,
    // groups: false,
    goodFor:  "",
    keywords: this.props.widget === "vaccine" ? "covid vaccine" : this.props.widget === "americorps" ? "americorps" : "",
    optionsState: null
  };

  componentDidMount(){
    if(window.location.pathname=== "/searchwidgetvaccine"){
      localStorage.setItem("widget", "vaccine")
    }
    if(window.location.pathname=== "/searchwidgetamericorps"){
      localStorage.setItem("widget", "americorps")
    }
    if(!this.props.widget && JSON.parse(localStorage.getItem("form"))){
      this.setState({
        location: JSON.parse(localStorage.getItem("form")).location || "California, USA",
        virtual: JSON.parse(localStorage.getItem("form")).virtual,
        category: JSON.parse(localStorage.getItem("form")).category || "", 
        goodFor:  JSON.parse(localStorage.getItem("form")).goodFor || "",
        keywords: JSON.parse(localStorage.getItem("form")).keywords || "",
      })
    }
    else if (this.props.widget === "climate" && JSON.parse(localStorage.getItem("form"))) {
      this.setState({
        location: JSON.parse(localStorage.getItem("form")).location || "California, USA",
        virtual: JSON.parse(localStorage.getItem("form")).virtual,
        category: "climate", 
        goodFor:  JSON.parse(localStorage.getItem("form")).goodFor || "",
        keywords: JSON.parse(localStorage.getItem("form")).keywords || "",
      })
    }
    else if (this.props.widget === "vaccine" && JSON.parse(localStorage.getItem("form"))) {
      this.setState({
        location: JSON.parse(localStorage.getItem("form")).location || "California, USA",
        virtual: JSON.parse(localStorage.getItem("form")).virtual,
        category: "vaccine",
        goodFor:  JSON.parse(localStorage.getItem("form")).goodFor || "",
        keywords: "covid vaccine",
      })
    }
    else if (this.props.widget === "americorps" && JSON.parse(localStorage.getItem("form"))) {
      this.setState({
        location: JSON.parse(localStorage.getItem("form")).location || "California, USA",
        virtual: JSON.parse(localStorage.getItem("form")).virtual,
        category: "americorps",
        goodFor:  JSON.parse(localStorage.getItem("form")).goodFor || "",
        keywords: "americorps",
      })
    }
  }

  handleInputChange = (e) => {
    this.setState({
      location: e.target.value,
    }) 
  }

  handleKeywordsInputChange = (e) => {
    this.setState({
      keywords: e.target.value
    })
  }

  handleCatChange = (ex) => {
    if (ex.target.value === "vaccine") {
      localStorage.setItem("widget", "vaccine")
      this.setState({
        category: ex.target.value,
        keywords: "covid vaccine"
      })
    } else if (this.state.category === "vaccine" && ex.target.value !== "vaccine") {
      localStorage.removeItem("widget")
      this.setState({
        category: ex.target.value,
        keywords: ""
      })
    }
    if (ex.target.value === "americorps") {
      localStorage.setItem("widget", "americorps")
      this.setState({
        category: ex.target.value,
        keywords: "americorps"
      })
    } else if (this.state.category === "americorps" && ex.target.value !== "americorps") {
      localStorage.removeItem("widget")
      this.setState({
        category: ex.target.value,
        keywords: ""
      })
    }
    else {
    this.setState({
      category: ex.target.value,
      keywords: `${this.state.keywords}`
    })
   }
  }

  handleGfChange = (en) => {
    this.setState({
      goodFor: en.target.value
    })
  }

  handleFilter = (evt) => {
    if(evt.target.name === "virtual"){
      this.setState({
        virtual: !this.state.virtual
      })
    }
    // this.setState({
    //   [evt.target.name] : !this.state[`${evt.target.name}`]
    // })
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return (
      <div className={ window.location.pathname=== "/" ? 'form-cont-results' : 'form-cont'}>
        <div className={ window.location.pathname=== "/" ? 'form-inner' : 'form-inner'}>
        { 
          window.location.pathname=== "/" || params.header === "false"
            ? <div style={{display: "none"}}></div> 
            : <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                <img src="https://www.californiavolunteers.ca.gov/wp-content/uploads/sites/116/2020/04/Californians-For-All.png" alt="Californians-for-all-logo" style={{position: "relative", maxWidth: "250px"}} />
                <div className={"widget-middle"}>
                  <div className={"title"}>
                    Join #CaliforniansForAll
                  </div>
                  <div className={"subtitle"}>
                    Answer the Governor's call to service by helping your community
                  </div>
                  <div className={"action"}>
                    Search for volunteer opportunities in your area
                  </div>
                </div>
              </div> 
          }
        <form className="search" autoComplete="off">
            <div className={window.location.pathname=== "/" ? 'form-select-cont-results' : "form-select-cont"}>
              <input
                style={this.state.location === "" ? {opacity: 0.8} : null}
                onChange={this.handleInputChange}
                value={this.state.location}
                name={"location"}
                type={"text"}
                placeholder={"Enter City or Zip Code"}
                id={"cation-input"}
                className={"location-input"}
              />
             <div className={window.location.pathname=== "/" ? 'cat-check-parent-results' : "cat-check-parent"}>
             {/* <label>Categories</label> */}
               <select value={this.state.category} onChange={this.handleCatChange}>
                 {/* {console.log('category ', this.state.category)} */}
                 <option value={""} id={"cation-input"}>Choose Category (optional)</option>
                 <option value={"vaccine"} id={"cation-input"}>Vaccine</option>
                 <option value={"food"} id={"cation-input"}>Food</option>
                 <option value={"climate"} id={"cation-input"}>Climate</option>
                 <option value={"education"} id={"cation-input"}>Education</option>
                 <option value={"disasters"} id={"cation-input"}>Disasters</option>
                 <option value={"americorps"} id={"cation-input"}>AmeriCorps</option>
                 
               </select>
             </div>
             <div className={window.location.pathname=== "/" ? 'cat-check-parent-results' : "cat-check-parent"}>
             {/* <label>Great For</label> */}
               <select value={this.state.goodFor} onChange={this.handleGfChange}>
                 {/* {console.log('goodFor ', this.state.goodFor)} */}
                 <option value={""} id={"cation-input"}>Great For (optional)</option>
                 <option value={"groups"} id={"cation-input"}>Groups</option>
                 <option value={"seniors"} id={"cation-input"}>Seniors</option>
                 <option value={"teens"} id={"cation-input"}>Teens</option>
                 <option value={"kids"} id={"cation-input"}>Kids</option>
               </select>
             </div>
             {/* <div className={window.location.pathname=== "/" ? 'keyword-cont-results' : "keyword-cont"}> */}
             {this.props.widget === "vaccine" || this.state.category === "vaccine" || this.props.widget === "americorps" || this.state.category === "americorps" ? 
             <input
              onChange={this.handleKeywordsInputChange}
              value={this.state.keywords}
              name={"keywords"}
              type={"text"}
              placeholder={"Filter by keywords (optional)"}
              id={"cation-input"}
              className={"keywords-input hide-keyword-input"}
            />
             : 
              <input
                onChange={this.handleKeywordsInputChange}
                value={this.state.keywords}
                name={"keywords"}
                type={"text"}
                placeholder={"Filter by keywords (optional)"}
                id={"cation-input"}
                className={"keywords-input"}
              />
            }
            {/* </div> */}
            <div className={"virt-opp-cont"}>
              <input
                onChange={this.handleFilter}
                checked={this.state.virtual}
                value={this.state.virtual}
                name={"virtual"}
                type={"checkbox"}
                id={"cation-input"}
                />
              <label>View ONLY virtual opportunites?</label>
             </div>
          </div>


        <button 
            type="submit"
            onClick={(event)=>{
                event.preventDefault()
                this.props.handleFormSubmit(event, this.state)
            }}
            className={window.location.pathname=== "/" ? 'btn btn-success' : "btn btn-success w-btn"}
        >
           { window.location.pathname=== "/" ? <span>Search Volunteer Opportunities</span> : <span>Volunteer For California</span>}
          
        </button>
        </form>
          { 
           false 
              ? <div style={{display: "none"}}></div> 
              : <div className={"form-powered-by"}>
                  <span style={{fontSize: "16px", color: "#214080"}}>
                    Powered by:
                  </span>
                  <img src="https://solutions.volunteermatch.org/hs-fs/hubfs/VM%20logo%20color%20-%20black%20text%20no%20tagline%20-%20transparent.png?width=632&height=139&name=VM%20logo%20color%20-%20black%20text%20no%20tagline%20-%20transparent.png" alt="Volunteer-Match-Logo"></img>
                </div> 
          }
        </div>
      </div>
    );
  }
}

export default Form;
